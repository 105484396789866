<template>
  <div id="forgot_password">
    <div class="hero is-medium">
      <div class="hero-body">
        <loading v-if="loading">Processing...</loading>
        <ValidationObserver ref="observer" v-slot="{ passes }" v-else>
          <div class="columns is-multiline">
            <div class="column is-12">
              <simsed-logo width="48" font-size="1.5em" />
              <h1 class="has-text-centered">
                School information management application
              </h1>
            </div>
            <div class="column is-6 is-offset-3">
              <form @submit.prevent="passes(submit)">
                <SdInput
                  type="text"
                  rules="required|email"
                  label="Email"
                  v-model="email"
                  placeholder="Enter email"
                />

                <button
                  type="submit"
                  class="button btn-120 is-success is-capitalized is-pulled-right mt-3"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import SdInput from '@/components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import SimsedLogo from '../components/SimsedLogo'

export default {
  name: 'subject',
  data() {
    return {
      schoolId: null,
      loading: false,
      pageTitle: 'Forgot Password',
      email: null,
    }
  },
  methods: {
    submit() {
      this.loading = true
      const auth = getAuth()
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.$router.push('/log_in')
          this.loading = false
          this.$buefy.notification.open({
            duration: 5000,
            message: 'Check your email for the password reset link.',
            position: 'is-top',
            type: 'is-info',
            hasIcon: true,
          })
        })
        .catch((error) => {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top',
            type: 'is-danger',
            hasIcon: true,
          })
          this.loading = false
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
    SimsedLogo,
  },
}
</script>
